import { template as template_95d8b4b3e5f24babb9df6ab688f9054e } from "@ember/template-compiler";
const FKControlMenuContainer = template_95d8b4b3e5f24babb9df6ab688f9054e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
