import { template as template_b6482aa901544b8d9e8cb210768fcdce } from "@ember/template-compiler";
const FKLabel = template_b6482aa901544b8d9e8cb210768fcdce(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
