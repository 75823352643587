import { template as template_8d048ef9794b4be49337a92f4777a54b } from "@ember/template-compiler";
const FKText = template_8d048ef9794b4be49337a92f4777a54b(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
