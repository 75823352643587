import { template as template_2a0e13185b9b478eadb609dc93b27fdd } from "@ember/template-compiler";
const WelcomeHeader = template_2a0e13185b9b478eadb609dc93b27fdd(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
